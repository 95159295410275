<template>
    <router-link :to="localePrefix" class="logo">
        <img src="../../assets/images/logo.svg" alt="Abellio" />
        <!-- <img src="../../assets/images/logo.svg" alt="Abellio" class="logo_color" /> -->
    </router-link>
</template>

<script>
    import {mapState} from 'vuex';

    export default {
        name: "Logo",
        computed: {
            ...mapState([
                'locales'
            ]),
            localePrefix() {
                return (this.locales.currentLocale != this.locales.defaultLocale) ? '/'+this.locales.currentLocale : '/';
            }
        }
    }
</script>

<style scoped>

</style>
