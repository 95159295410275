<template>
  <div v-if="region.currentRegion" class="left_buttons_wrapper">
    <a data-v-553aba40="" href="/" aria-current="page" class="logo logo-mobile">
      <img src="../../assets/images/logo.svg" alt="Abellio" />
    </a>
    <a
      href="#"
      @click.prevent="timetableToggle"
      class="left_button d-flex justify-content-center align-items-center"
    >
      <!-- <i class="ab-icon-timetable"></i> -->
      <i class="ab-icon-location"></i>
    </a>
    <!-- <router-link
      :to="'/karriere'"
      class="left_button d-flex justify-content-center align-items-center"
    >
      <i class="ab-icon-job"></i>
    </router-link> -->
    <router-link
      v-if="page2"
      :to="page2"
      class="left_button d-flex justify-content-center align-items-center"
    >
      <i class="ab-icon-map"></i>
    </router-link>
    <router-link
      v-if="page3"
      :to="page3"
      class="left_button d-flex justify-content-center align-items-center"
    >
      <i class="ab-icon-email"></i>
    </router-link>
    <slot></slot>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'LeftButtons',
  data() {
    return {
      page1: '',
      page2: '',
      page3: '',
    }
  },
  computed: {
    ...mapState(['region', 'locales', 'timetable', 'globalPopup']),
  },
  methods: {
    timetableToggle: function () {
      this.$store.commit(
        'globalPopup/setTimetableOpen',
        !this.globalPopup.isTimetableOpen,
      )
    },
    getPages() {
      this.$store.dispatch('settings/getAll', 13).then((data) => {
        this.page2 = data.urls.left_nav_second_url[this.locales.defaultLocale]
        if (data.urls.left_nav_second_url[this.locales.currentLocale] != '') {
          this.page2 = data.urls.left_nav_second_url[this.locales.currentLocale]
        }

        this.page3 = data.urls.left_nav_third_url[this.locales.defaultLocale]
        if (data.urls.left_nav_third_url[this.locales.currentLocale] != '') {
          this.page3 = data.urls.left_nav_third_url[this.locales.currentLocale]
        }
      })
    },
  },
  created() {
    this.getPages()
  },
  watch: {
    'region.currentRegion': function () {
      this.getPages()
    },
    'locales.currentLocale': function () {
      this.getPages()
    },
  },
}
</script>

<style scoped></style>
